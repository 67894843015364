import Vue from "vue";
import Vuex from "vuex";
import Toasted from 'vue-toasted';
import callToAction from './modules/call-to-action/store'
Vue.use(Vuex);
//Vue.use(Toasted);

export default new Vuex.Store({
  state: {
    $loading: false,
    test: "",
    toasted: Toasted,
    quick_setup: false,
    menu_tab: '',
  },
  mutations: {
    changeTest(state, new_value) {
      state.test = new_value
    },
    quickSetup(state) {
      state.quick_setup = true
    }
  },
  actions: {
  },
  modules: {
    callToAction
  }
})
