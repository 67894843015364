
export default {
    props: ['active'],
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}