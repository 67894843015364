export default {

    userId: (state, payload) => {
        state.userId = payload
        console.log('mutations userId run');
    },
    userEmail: (state, payload) => {
        state.userEmail = payload
        console.log('mutations userEmail run');
    },
    template: (state, payload) => {
        state.template = payload
        console.log('mutations template run');
    }
}