import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        template: "",
        templatePopup: '',
        listTemplate: '',
        dataTemplate: '',
        editTemplate: '',
        listCampaign: '',
        campaign: '',
        userId: '',
        userEmail: '',
        id: ''

    },
    getters,
    mutations,
    actions
};
