import Vue from "vue"
import VueRouter from "vue-router"
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
    // "/"
    {
        path: "/",
        name: "dashboard",
        redirect: { name: 'homepage' },
        component: () => import(/* webpackChunkName: "Dashboard" */ "@/views/dashboard/dashboard.vue"),
        children: [
            {
                path: 'homepage',
                name: 'homepage',
                props: true,
                component: () => import(/* webpackChunkName: "Homepage" */ "./components/dashboard/home/homepage.vue")
            },
            {
                path: 'game-list',
                name: 'game-list',
                props: true,
                component: () => import(/* webpackChunkName: "Gamelist" */ "./components/dashboard/game-list/game-list.vue")
            },
            {
                path: '/campaign-list',
                name: 'campaign-list',
                component: () => import(/* webpackChunkName: "CreateGame" */ "./components/gamification/campaign-list/campaign-list.vue")
            },
            // {
            //     path: 'create-game',
            //     name: 'create-game',
            //     props: true,
            //     component: () => import(/* webpackChunkName: "CreateGame" */ "./components/dashboard/create-game/create-game.vue")
            // },
            // {
            //     path: 'game-detail',
            //     name: 'game-detail',
            //     props: true,
            //     component: () => import(/* webpackChunkName: "GameDetail" */ "./components/dashboard/game-detail/game-detail.vue")
            // },
            {
                path: 'store-list',
                name: 'store-list',
                props: true,
                component: () => import(/* webpackChunkName: "StoreList" */ "./components/dashboard/store-list/store-list.vue")
            },
            {
                path: 'employee',
                name: 'employee',
                props: true,
                component: () => import(/* webpackChunkName: "Employee" */ "./components/dashboard/employee/employee.vue")
            },
            {
                path: 'update-business',
                name: 'update-business',
                props: true,
                component: () => import(/* webpackChunkName: "UpdateBusiness" */ "./components/dashboard/update-business/update-business.vue")
            },
            // call to action
            {
                path: 'call-to-action',
                name: 'call-to-action',
                props: true,
                component: () => import('@/components/call-to-action/Index.vue'),
                children: [
                    {
                        path: 'campaigns',
                        name: 'campaigns',
                        props: true,
                        component: () => import('@/components/call-to-action/campaigns/Campaigns.vue'),
                    },
                    {
                        path: 'new',
                        name: 'new',
                        props: true,
                        component: () => import('@/components/call-to-action/new-campaign/New-campaign.vue'),
                    },
                    {
                        path: 'edit',
                        name: 'edit',
                        props: true,
                        component: () => import('@/components/call-to-action/edit-campaign/Edit-campaign.vue'),
                    },
                    {
                        path: 'templates',
                        name: 'templates',
                        props: true,
                        component: () => import('@/components/call-to-action/new-template/New-template.vue')
                    }
                ]
            },

        ]
    },
    // "/store"
    {
        path: "/store",
        name: "store",
        component: () => import(/* webpackChunkName: "Store" */ "./views/selling-page/store-inside.vue"),
        children: [
            {
                path: 'store-order',
                name: 'store-order',
                props: true,
                component: () => import(/* webpackChunkName: "StoreOrder" */ "./components/selling-page/store-order/store-order.vue")
            },
            {
                path: 'store-category',
                name: 'store-category',
                props: true,
                component: () => import(/* webpackChunkName: "StoreCategory" */ "./components/selling-page/store-category/store-category.vue")
            },
            {
                path: 'store-product',
                name: 'store-product',
                props: true,
                component: () => import(/* webpackChunkName: "StoreProduct" */ "./components/selling-page/store-product/store-product.vue")
            },
            {
                path: 'store-customer',
                name: 'store-customer',
                props: true,
                component: () => import(/* webpackChunkName: "StoreCustomer" */ "./components/selling-page/store-customer/store-customer.vue")
            },
            {
                path: 'store-setting',
                name: 'store-setting',
                props: true,
                component: () => import(/* webpackChunkName: "StoreSetting" */ "./components/selling-page/store-setting/store-set.vue")
            }
        ]
    },
    // "/login"
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "Login" */ "./views/login/login.vue"),
    },
    // "payment"
    {
        path: "/payment",
        component: () => import(/* webpackChunkName: "Payment" */ "./components/selling-page/receipt/payment-bill.vue"),
        name: "payment"
    },
    // "/create-game"
    {
        path: '/create-game',
        name: 'create-game',
        component: () => import(/* webpackChunkName: "CreateGame" */ "./views/create-game/create-game.vue")
    },
    // "/game-detail"
    {
        path: '/game-detail',
        name: 'game-detail',
        component: () => import(/* webpackChunkName: "GameDetail" */ "./views/game-detail/game-detail.vue")
    },
    // "deliver"
    {
        path: "/deliver",
        component: () => import(/* webpackChunkName: "Deliver" */ "./components/selling-page/receipt/deliver-bill.vue"),
        name: "deliver"
    },
    // "/business"
    {
        path: "/business",
        name: "business",
        component: () => import(/* webpackChunkName: "BusinessDashboard" */ "@/views/business/business-dashboard.vue"),
    },
    // "/profile"
    {
        path: "/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "Profile" */ "@/views/profile/profile.vue"),
    },
    // "/widget-payment"
    {
        path: "/widget-payment",
        name: "WidgetPayment",
        component: () => import(/* webpackChunkName: "WidgetPayment" */ "@/views/payment/bill/bill.vue")
    },
    // "/payment-method"
    {
        path: "/payment-method",
        name: "PaymentMethod",
        component: () => import(/* webpackChunkName: "PaymentMethod" */  "@/views/payment/methods/methods.vue"),
    },
    {
        path: "/create-payment",
        name: "CreatePayment",
        component: () => import(/* webpackChunkName: "CreatePayment" */  "@/views/payment/create-payment/create-payment.vue"),
    },
    // "/payment-share"
    {
        path: "/payment-share",
        name: "PaymentShare",
        component: () => import(/* webpackChunkName: "PaymentShare" */  "./views/payment/share/share.vue")
    },

    // "/payment-bill"
    // {
    //     path: '/payment-bill',
    //     name: 'PaymentBill',
    //     component: () => import("./views/payment-bill/payment-bill.vue")
    // },
    // "*"

    // * ////////////////
    // * Campaign END
    // * ////////////////
    {
        path: '/campaign',
        name: 'campaign',
        component: () => import(/* webpackChunkName: "CreateGame" */ "./components/gamification/campaign/campaign.vue")
    },

    {
        path: '/manage-point',
        name: 'manage-point',
        component: () => import(/* webpackChunkName: "Manage-Point" */ "./components/gamification/manage-point/manage-point.vue")
    },
    
    {
        path: '/create-campaign',
        name: 'create-campaign',
        component: () => import(/* webpackChunkName: "CreateGame" */ "./components/gamification/create-campaign/create-campaign.vue")
    },

    {
        path: '/voucher',
        name: 'voucher',
        component: () => import(/* webpackChunkName: "CreateGame" */ "./components/gamification/voucher/voucher.vue")
    },
    // * ////////////////
    // * Campaign END
    // * ////////////////

    {
        path: "*",
        redirect: "homepage",
    },

]

const router = new VueRouter({
    routes
})

export default router
