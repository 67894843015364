import { render, staticRenderFns } from "./loading.html?vue&type=template&id=20a3c556&scoped=true&external"
import script from "./loading.js?vue&type=script&lang=js&external"
export * from "./loading.js?vue&type=script&lang=js&external"
import style0 from "./loading.vue?vue&type=style&index=0&id=20a3c556&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a3c556",
  null
  
)

export default component.exports