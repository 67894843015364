import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/index"
// import "./registerServiceWorker";
import Toasted from "vue-toasted";
import CKEditor from '@ckeditor/ckeditor5-vue'
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Cleave from 'vue-cleave-component';
// const bodyScrollLock = require('body-scroll-lock');
import base_api from './services/base-api';
import './components/component-share/date-ranger/date-ranger.scss'
import VueLazyload from 'vue-lazyload'
import jquery from 'jquery'
window.$ = jquery
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// const bodyScrollLock = require('body-scroll-lock');
import Vue2TouchEvents from 'vue2-touch-events'

// custom code 
import decorationText from './services/decoration'
import './assets/custom/custom.scss'
import Dropdown from './assets/custom/js/dropdown'
Dropdown.setup()
import TabsB from './assets/custom/js/tabs'
TabsB.setup()


Vue.use(VueSweetalert2)
Vue.use(Toasted)
Vue.use(CKEditor)
Vue.use(Cleave)
Vue.use(VueLazyload)
Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

//Khai bao bien global
Vue.prototype.$configs = { api: base_api }
Vue.prototype.$http = axios
Vue.prototype.$loading = false
Vue.prototype.$userLogged = JSON.parse(localStorage.getItem("user-cms")) || null


//Check token login
if (Vue.prototype.$userLogged) {
  Vue.prototype.$http.defaults.headers.common["x-botup-token"] = Vue.prototype.$userLogged.token;
  Vue.prototype.$http.get(Vue.prototype.$configs.api + "users/users/getuser")
    .then((reponses) => {
      if (reponses.data.data) {
        //  reponses.data.data.token = Vue.prototype.$userLogged.token;
        localStorage.setItem("user-cms", JSON.stringify(reponses.data.data));
      }
      else
        localStorage.removeItem("user-cms");
    })
    .catch((err) => {
      console.log(2);
      console.log(err);
      localStorage.removeItem("user-cms");
    })
}

Vue.filter('formatVND', function (value) {
  return decorationText.formatVND(value)
})
Vue.filter('formatTime', function (value) {
  return decorationText.formatTime(value)
})
Vue.filter('formatTimeString', function (value) {
  return decorationText.formatTimeString(value)
})
Vue.filter('formatAmount', function (value) {
  value = Number(value)
  return value.toLocaleString('it-IT')
})
Vue.directive('tooltip', function (el, binding) {
  $(el).tooltip({
    title: binding.value,
    placement: binding.arg,
    trigger: 'hover',
  })
})

// lock-body-scroll
// const disableBodyScroll = bodyScrollLock.disableBodyScroll;
// const targetElement = document.querySelector('#app');
// disableBodyScroll(targetElement);

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

router.beforeEach((to, from, next) => {
  if (to.path == '/game-list' || to.path == '/store-list')
    app.$store.state.menu_tab = to.path
  else
    app.$store.state.menu_tab = ''

  app.$store.state.$loading = true

  let user = localStorage.getItem("user-cms")
  if (to.name != 'Login' &&
    to.name != 'WidgetPayment' &&
    to.name != "PaymentMethod" &&
    to.name != "payment" &&
    to.name != "deliver" &&
    to.name != "PaymentShare" &&
    // to.name != "PaymentBill" &&
    !user) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
});

router.afterEach((to, from) => {
  app.$store.state.$loading = false
})



