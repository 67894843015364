let api;
if(process.env.NODE_ENV === "production"){
    // api = 'https://sandbox.merchant.vn/v1/' // new dev
    api = "https://api-cms.appon.vn/v1/"; //production
} else {
    // api = "https://devbbh.tk/v1/";
    // api = 'https://sandbox.merchant.vn/v1/' // new dev
    api = "https://api-cms.appon.vn/v1/"; //production
    // api = "http://localhost:1337/v1/"; //client
}
export default api
