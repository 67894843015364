import api from "./base-api";
import req from "../fetch.js";

let apiDev = 'http://localhost:1337/v1/'
export default {
    callToAction: {
        campaign(body, cb) {
            req.post(api + 'call-to-action/campaign/index', body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },
        template(body, cb) {
            req.post(api + 'call-to-action/template/index', body, (e, r) => {
                cb ? cb(e, r) : false
            })
        }
    }
}